import { Currency, ETHER, Token } from '@pancakeswap/sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import usdb from "swapicons/usdb.png"
import weth from "swapicons/weth.png"
import btc from "swapicons/Bitcoin.svg"
import gmt from "swapicons/gmt.png"
import eth from "swapicons/eth.png"
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import getTokenLogoURL from '../../utils/getTokenLogoURL'
import Logo from './Logo'

// Styled component for the logo with dynamic size
const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

// Statically defined logos for specific tokens
const staticLogos: { [address: string]: string } = {
  '0x4300000000000000000000000000000000000004': weth,
  '0x4300000000000000000000000000000000000003': usdb, 
  "0xF7bc58b8D8f97ADC129cfC4c9f45Ce3C0E1D2692": btc,
  "0xB60dF4A1db61B173d0d774ffa813C3c37070E38b": gmt,
  'ETHER': eth
}

// CurrencyLogo component
export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) {
      const staticLogo = staticLogos.ETHER
      if (staticLogo) {
        return [staticLogo]
      }
      return []
    }

    if (currency instanceof Token) {
      const staticLogo = staticLogos[currency.address]
      if (staticLogo) {
        return [staticLogo]
      }
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }
      return [getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
