import { useContext, useEffect } from 'react'
import { ThemeContext as StyledThemeContext } from 'styled-components'
import { useThemeManager } from 'state/user/hooks'

const useTheme = () => {
  const [isDark, toggleTheme] = useThemeManager()
  const theme = useContext(StyledThemeContext)

  useEffect(() => {
    if (!isDark) {
      toggleTheme()
    }
  }, [isDark, toggleTheme])

  const alwaysDarkTheme = () => {
    if (!isDark) {
      toggleTheme()
    }
  }

  return { isDark: true, theme, toggleTheme: alwaysDarkTheme }
}

export default useTheme
