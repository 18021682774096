import styled from 'styled-components';           

const Background = styled.div`        
  background-image: url('') !important; 
  background-size: contain !important;
  background-position: right center !important;
  background-repeat: no-repeat !important;
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  z-index: -1 !important;


  @media (max-width: 768px) {
    background-size: cover !important; 
    background-position: center !important; 
  }
`;



export default Background;