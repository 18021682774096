import React from 'react'
import { Menu as UikitMenu } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import { useTokenBalanceOfAddress } from 'hooks/useTokenBalance'
import useTheme from 'hooks/useTheme'
import useAuth from 'hooks/useAuth'
import { usePriceCakeBusd } from 'state/farms/hooks'
import { useProfile } from 'state/profile/hooks'
import config from './config'





const Menu = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { isDark, toggleTheme } = useTheme()
  
  const cakeInCakeUSDLp = useTokenBalanceOfAddress("0xB60dF4A1db61B173d0d774ffa813C3c37070E38b","0xb42B986Fd135E4631454d509BB434317B4850781")
  const usdInCakeUSDLp = useTokenBalanceOfAddress("0x4300000000000000000000000000000000000003","0xb42B986Fd135E4631454d509BB434317B4850781")
  const ourCakePrice = usdInCakeUSDLp.dividedBy(cakeInCakeUSDLp)


  const cakePriceUsd = ourCakePrice
  const { profile } = useProfile()
  const { currentLanguage, setLanguage, t } = useTranslation()
  


  return (
    <UikitMenu
      account={account}
      login={login}
      logout={logout}
      isDark={isDark}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      cakePriceUsd={cakePriceUsd.toNumber()}
      links={config(t)}
      profile={{
        username: profile?.username,
        image: profile?.nft ? `/images/nfts/${profile.nft?.images.sm}` : undefined,
        profileLink: '/profile',
        noProfileLink: '/profile',
        showPip: !profile?.username,
      }}
      {...props}
    />
  )
}

export default Menu
