import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const redirectIfNeeded = () => {
  const currentUrl = window.location.href;
  const shouldRedirect = !currentUrl.includes('/swap') && !currentUrl.includes('/liquidity') && 
                         (currentUrl.includes('prosperityswap.com') && !currentUrl.includes('farm.prosperityswap.com'));
  if (shouldRedirect) {
    window.location.href = 'https://farm.prosperityswap.com';
  }
}

redirectIfNeeded();

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: 'https://www.farm.prosperityswap.com/',
  },
  {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: 'https://www.farm.prosperityswap.com/farms',
  },
  {
    label: t('Pool'),
    icon: 'PoolIcon',
    href: 'https://www.farm.prosperityswap.com/pools',
  },
  {
    label: t('Swap'),
    icon: 'TradeIcon',
    items: [
      {
        label: 'Swap',
        icon: "TradeIcon",
        href: '/swap'
      },
      {
        label: 'Liquidity',
        href: '/liquidity',
      },
    ],
  },
  {
    label: t('Price Info'),
    icon: 'InfoIcon',
    href: "https://blastscan.io/token/0xFf08733849cf99408C21b0b968d7e3a724c9ec48"
  },
  {
    label: 'More / Team',
    icon: 'MoreIcon',
    items: [

      {
        label: 'Contract Developer',
        href: 'https://linkedin.com/in/frank-kulow',
      },

      {
        label: 'UI / UX Developer',
        href: 'https://www.linkedin.com/in/devrim-cebe/',
      },

      {
        label: 'Team',
        href: '/team',
      },
    ],
  },

]

export default config
