import { ChainId, Token } from '@pancakeswap/sdk'

export const CAKE: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xB60dF4A1db61B173d0d774ffa813C3c37070E38b',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
}
export const BUSD: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x4300000000000000000000000000000000000003',
    18,
    'USDB',
    'Binance USD',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'USDB',
    'Binance USD',
  ),
}

export const WBNB: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x4300000000000000000000000000000000000004',
    18,
    'WBNB',
    'Binance bnb',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'USDB',
    'Binance USD',
  ),
}






// export const WBNB = new Token(ChainId.MAINNET,'0x4300000000000000000000000000000000000004', 18, 'WBNB', 'Wrapped BNB')  // das war der fehler FRK88 !!! 
export const BTCB = new Token(ChainId.MAINNET,'0xF7bc58b8D8f97ADC129cfC4c9f45Ce3C0E1D2692', 8, 'BTCB', 'Bitcoin')
// export const CAKE = new Token(ChainId.MAINNET,'0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82', 18, 'CAKE', 'PancakeSwap Token')
// export const BUSD = new Token(ChainId.MAINNET,'0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', 18, 'USDB', 'Binance USD')



const tokens = {

  bnb: {
    symbol: 'ETH',
    projectLink: '',
  },

  cake: {
    symbol: 'CAKE',
    address: {
      81457: '0xB60dF4A1db61B173d0d774ffa813C3c37070E38b',
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },

  busd: {
    symbol: 'USDB',
    address: {
      81457: '0x4300000000000000000000000000000000000003',
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },

  wbnb: {
    symbol: 'WBNB',
    address: {
      81457: '0x4300000000000000000000000000000000000004',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },



  btcb: {
    symbol: 'BTCB',
    address: {
      81457: '0xF7bc58b8D8f97ADC129cfC4c9f45Ce3C0E1D2692',
      56: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  }

}

export default tokens
